export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  if (!config.public.googleClientId) return

  const { onLoginGoogle } = useAuth()
  const toast = useToast()

  google.accounts.id.initialize({
    client_id: config.public.googleClientId,
    callback: (response) => onLoginGoogle(response, toast),
  })
})
