import type { ToastServiceMethods } from 'primevue/toastservice'

export async function setupHeaders (headers: HeadersInit) {
  const { currentCompany } = useCompany()
  const { logout, token} = useAuth()

  if (token.value) {
    try {
      const decodedToken = decodeJwt(token.value)
      const diff: number = (decodedToken.exp * 1000 - Date.now()) / 1000

      if (diff < 60) {
        const { doRefresh } = useAuth()
        token.value = await doRefresh()
      }
    } catch (e) {
      await logout()
      return
    }

    if (Array.isArray(headers)) {
      headers.push(['Authorization', `Bearer ${token.value}`])
    } else if (headers instanceof Headers) {
      headers.set('Authorization', `Bearer ${token.value}`)
    } else {
      headers.Authorization = `Bearer ${token.value}`
    }
  }

  if (currentCompany.value) {
    if (Array.isArray(headers)) {
      headers.push(['X-Company-Id', String(currentCompany.value.id)])
    } else if (headers instanceof Headers) {
      headers.set('X-Company-Id', String(currentCompany.value.id))
    } else {
      headers["X-Company-Id"] = String(currentCompany.value.id)
    }
  }

  return headers
}

export function decodeJwt (token :string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export function errorsToToast (errors: BadRequest<{
  detail?: string,
  non_field_errors?: string[]
}>, toast?: ToastServiceMethods) {
  if (!toast) return

  if (!errors || !errors.data) {
    toast.add({
      detail: 'An unknown error has ocurred.',
      life: 3000,
      severity: 'error',
      summary: 'Error',
    })
  }

  Object.keys(errors.data).forEach(key => {
    if (key === 'detail') {
      toast.add({
        detail: errors.data.detail,
        life: 3000,
        severity: 'error',
        summary: 'Error',
      })
    } else {
      const messages = errors.data[key]
      messages.forEach((message: string) => {
        toast.add({
          detail: message,
          life: 3000,
          severity: 'error',
          summary: 'Error',
        })
      })
    }
  })
}

export function hashToObject(): Record<string, string> {
  const { hash } = useRoute()
  const hashes = hash.split('#')

  if (!hashes.length) return {}

  const dict: Record<string, string> = {}

  hashes.forEach((item: string) => {
    const parts = item.split('-')
    if (parts.length !== 2) return
    const key = parts[0]

    if (dict[key]) return
    dict[key] = parts[1]
  })

  return dict
}
