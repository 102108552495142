import { default as indexbKarka2I2QMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/_billing/[id]/index.vue?macro=true";
import { default as index3hMo6rlrAdMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/_billing/index.vue?macro=true";
import { default as indexAdzXM5xWWjMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/company/index.vue?macro=true";
import { default as indexAY2cLgtZtOMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/custom-fields/index.vue?macro=true";
import { default as indexaJfpCbkn3OMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/index.vue?macro=true";
import { default as index0VKTQIqCJ6Meta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/tags/index.vue?macro=true";
import { default as indexWsiMyNVqZOMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/templates/index.vue?macro=true";
import { default as indexTqBUYi6Ne1Meta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/trust-hub/index.vue?macro=true";
import { default as changeHOe3OTG98KMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/[id]/change.vue?macro=true";
import { default as indexGzhb72vhTEMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/[id]/index.vue?macro=true";
import { default as set_45password0tgiWDH6naMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/[id]/set-password.vue?macro=true";
import { default as addj6jf4WsnsQMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/add.vue?macro=true";
import { default as indexl1mbbfJaZdMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/index.vue?macro=true";
import { default as indexgz5wF7bdfoMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/work-types/index.vue?macro=true";
import { default as configurationCajYMfBV6EMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration.vue?macro=true";
import { default as calendarL30ueAfQfQMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/calendar.vue?macro=true";
import { default as _91id_936qcMQx1LqGMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/calls/[id].vue?macro=true";
import { default as indexoezzfpLqF0Meta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/calls/index.vue?macro=true";
import { default as change6nHpcMrQmTMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/campaigns/[id]/change.vue?macro=true";
import { default as indexmcEwd1tFM3Meta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/campaigns/[id]/index.vue?macro=true";
import { default as addHQVCfeBNmWMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/campaigns/add.vue?macro=true";
import { default as indexmRf998UbznMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/campaigns/index.vue?macro=true";
import { default as index0LLyp4iWrTMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/conversations/index.vue?macro=true";
import { default as indexcI6LVrcOPOMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/index.vue?macro=true";
import { default as changek6SlgJH0SIMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/[id]/change.vue?macro=true";
import { default as indexr1t4FmWgHiMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/[id]/index.vue?macro=true";
import { default as merget3uhfDjrHwMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/[id]/merge.vue?macro=true";
import { default as add5bwrHFHwEzMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/add.vue?macro=true";
import { default as index7d26gUKGaKMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/index.vue?macro=true";
import { default as changeuHp6Wf13oWMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/phone-numbers/[id]/change.vue?macro=true";
import { default as indexrnMZiDoxQMMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/phone-numbers/[id]/index.vue?macro=true";
import { default as indexjoDOiYSiNpMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/phone-numbers/index.vue?macro=true";
import { default as search_45availableEr94PBFkwgMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/phone-numbers/search-available.vue?macro=true";
import { default as crmuUJUofUJ3bMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm.vue?macro=true";
import { default as indexEpg8uRVAnLMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/index.vue?macro=true";
import { default as _91companyId_93dwWnTdX1fvMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId].vue?macro=true";
import { default as changeYAFdy9pDjkMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/change.vue?macro=true";
import { default as indexIMtVGnmAy9Meta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/index.vue?macro=true";
import { default as loginYZhEwnhxmZMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/login.vue?macro=true";
import { default as _91token_93inWYPCuO6uMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completeNaGMV6bwQ9Meta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset/complete.vue?macro=true";
import { default as donesJnxMUduMtMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset/done.vue?macro=true";
import { default as indexwVeOkSp6DkMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset/index.vue?macro=true";
import { default as password_45reset8RBZFlk1QRMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset.vue?macro=true";
import { default as password5Bp9haZ9kgMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password.vue?macro=true";
import { default as privacy_45policy8S6lV2pP5pMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45userc9MSiTMlTMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/docs/terms-of-use.vue?macro=true";
import { default as indexTd5xN3gGzeMeta } from "/home/runner/work/callcenter-frontend/callcenter-frontend/pages/index.vue?macro=true";
export default [
  {
    name: _91companyId_93dwWnTdX1fvMeta?.name,
    path: "/:companyId()",
    meta: _91companyId_93dwWnTdX1fvMeta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId].vue"),
    children: [
  {
    name: configurationCajYMfBV6EMeta?.name,
    path: "configuration",
    meta: configurationCajYMfBV6EMeta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration.vue"),
    children: [
  {
    name: "companyId-configuration-_billing-id",
    path: "_billing/:id()",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/_billing/[id]/index.vue")
  },
  {
    name: "companyId-configuration-_billing",
    path: "_billing",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/_billing/index.vue")
  },
  {
    name: "companyId-configuration-company",
    path: "company",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/company/index.vue")
  },
  {
    name: "companyId-configuration-custom-fields",
    path: "custom-fields",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/custom-fields/index.vue")
  },
  {
    name: "companyId-configuration",
    path: "",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/index.vue")
  },
  {
    name: "companyId-configuration-tags",
    path: "tags",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/tags/index.vue")
  },
  {
    name: "companyId-configuration-templates",
    path: "templates",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/templates/index.vue")
  },
  {
    name: "companyId-configuration-trust-hub",
    path: "trust-hub",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/trust-hub/index.vue")
  },
  {
    name: "companyId-configuration-users-id-change",
    path: "users/:id()/change",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/[id]/change.vue")
  },
  {
    name: "companyId-configuration-users-id",
    path: "users/:id()",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/[id]/index.vue")
  },
  {
    name: "companyId-configuration-users-id-set-password",
    path: "users/:id()/set-password",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/[id]/set-password.vue")
  },
  {
    name: "companyId-configuration-users-add",
    path: "users/add",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/add.vue")
  },
  {
    name: "companyId-configuration-users",
    path: "users",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/users/index.vue")
  },
  {
    name: "companyId-configuration-work-types",
    path: "work-types",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/configuration/work-types/index.vue")
  }
]
  },
  {
    name: crmuUJUofUJ3bMeta?.name,
    path: "crm",
    meta: crmuUJUofUJ3bMeta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm.vue"),
    children: [
  {
    name: "companyId-crm-calendar",
    path: "calendar",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/calendar.vue")
  },
  {
    name: "companyId-crm-calls-id",
    path: "calls/:id()",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/calls/[id].vue")
  },
  {
    name: "companyId-crm-calls",
    path: "calls",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/calls/index.vue")
  },
  {
    name: "companyId-crm-campaigns-id-change",
    path: "campaigns/:id()/change",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/campaigns/[id]/change.vue")
  },
  {
    name: "companyId-crm-campaigns-id",
    path: "campaigns/:id()",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/campaigns/[id]/index.vue")
  },
  {
    name: "companyId-crm-campaigns-add",
    path: "campaigns/add",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/campaigns/add.vue")
  },
  {
    name: "companyId-crm-campaigns",
    path: "campaigns",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/campaigns/index.vue")
  },
  {
    name: "companyId-crm-conversations",
    path: "conversations",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/conversations/index.vue")
  },
  {
    name: "companyId-crm",
    path: "",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/index.vue")
  },
  {
    name: "companyId-crm-leads-id-change",
    path: "leads/:id()/change",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/[id]/change.vue")
  },
  {
    name: "companyId-crm-leads-id",
    path: "leads/:id()",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/[id]/index.vue")
  },
  {
    name: "companyId-crm-leads-id-merge",
    path: "leads/:id()/merge",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/[id]/merge.vue")
  },
  {
    name: "companyId-crm-leads-add",
    path: "leads/add",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/add.vue")
  },
  {
    name: "companyId-crm-leads",
    path: "leads",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/leads/index.vue")
  },
  {
    name: "companyId-crm-phone-numbers-id-change",
    path: "phone-numbers/:id()/change",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/phone-numbers/[id]/change.vue")
  },
  {
    name: "companyId-crm-phone-numbers-id",
    path: "phone-numbers/:id()",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/phone-numbers/[id]/index.vue")
  },
  {
    name: "companyId-crm-phone-numbers",
    path: "phone-numbers",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/phone-numbers/index.vue")
  },
  {
    name: "companyId-crm-phone-numbers-search-available",
    path: "phone-numbers/search-available",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/crm/phone-numbers/search-available.vue")
  }
]
  },
  {
    name: "companyId",
    path: "",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/[companyId]/index.vue")
  }
]
  },
  {
    name: "account-change",
    path: "/account/change",
    meta: changeYAFdy9pDjkMeta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/change.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexIMtVGnmAy9Meta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginYZhEwnhxmZMeta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/login.vue")
  },
  {
    name: password_45reset8RBZFlk1QRMeta?.name,
    path: "/account/password-reset",
    meta: password_45reset8RBZFlk1QRMeta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: password5Bp9haZ9kgMeta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/account/password.vue")
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/docs/privacy-policy.vue")
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/docs/terms-of-use.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexTd5xN3gGzeMeta || {},
    component: () => import("/home/runner/work/callcenter-frontend/callcenter-frontend/pages/index.vue")
  }
]