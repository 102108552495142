export default defineNuxtPlugin(() => {
  const initFacebookSDK = () => {
    const config = useRuntimeConfig()

    if (!config.public.fbAppId) return

    const { onLoginFacebook } = useAuth()

    window.fbAsyncInit = function () {
      FB.init({
        appId: config.public.fbAppId,
        xfbml: true,
        status: true,
        version: 'v20.0',
      })

      FB.getLoginStatus((response) => onLoginFacebook(response))
    }

    addFacebookSDK()
  }

  const addFacebookSDK = () => {
    const d = document;
    const s = "script";
    const id = "facebook-jssdk";
    const js = d.createElement(s);
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js.id = id;
    js.src = `https://connect.facebook.net/es_US/sdk.js`;
    fjs.parentNode?.insertBefore(js, fjs);
  }

  initFacebookSDK()
})
