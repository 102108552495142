import OneSignalVuePlugin, { useOneSignal } from '@onesignal/onesignal-vue3'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (!config.public.onesignalAppId) return

  nuxtApp.vueApp.use(OneSignalVuePlugin, {
    appId: config.public.onesignalAppId,
  })

  return {
    provide: {
      oneSignal: useOneSignal()
    }
  }
})
