export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const api = $fetch.create({
    baseURL: config.public.baseURL,

    async onRequest({ options }) {
      const headers = options.headers ||= {}
      options.headers = await setupHeaders(headers)
    },

    onResponseError({ response }) {
      if ([403, 404, 500].includes(response.status)) {
        showError({
          message: response._data.detail,
          statusCode: response.status,
        })
        return
      }
    },
  })

  return {
    provide: {
      api
    }
  }
})
