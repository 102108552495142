export function useCompany () {
  const currentCompany = useState<Company|null>('currentCompany', () => null)

  const refreshCompany = async () => {
    if (!currentCompany.value) return

    const { $api } = useNuxtApp()
    await $api<Company>(`/companies/${currentCompany.value.id}/`)
      .then(response => currentCompany.value = response)
  }

  const setCompany = (company?: Company) => {
    if (!company) return

    currentCompany.value = company
  }

  const clearCompany = () => {
    currentCompany.value = null
  }

  return { clearCompany, currentCompany, refreshCompany, setCompany }
}
