<template>
  <div class="flex h-screen items-center justify-center w-screen">
    <div class="space-y-10 text-center">
      <h2 class="font-bold text-8xl">{{ props.error?.statusCode }}</h2>
      <p class="text-lg">{{ props.error?.message }}</p>
      <Button
        size="large"
        label="Go Home"
        @click="handleError"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })
</script>
